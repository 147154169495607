<template>
  <div>
    <h1 class="title">PAGE 404</h1>

    <p class="notification is-info">
      Finner ikke denne siden ! ( {{ $route.fullPath }} )
      <router-link class="button is-white" to="/">Til forsiden....</router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: "404",
  components: {}
};
</script>
